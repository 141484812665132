exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-home-about-index-jsx": () => import("./../../../src/pages/home/about/index.jsx" /* webpackChunkName: "component---src-pages-home-about-index-jsx" */),
  "component---src-pages-home-contact-index-jsx": () => import("./../../../src/pages/home/contact/index.jsx" /* webpackChunkName: "component---src-pages-home-contact-index-jsx" */),
  "component---src-pages-home-index-jsx": () => import("./../../../src/pages/home/index.jsx" /* webpackChunkName: "component---src-pages-home-index-jsx" */),
  "component---src-pages-home-products-outsourcing-events-index-jsx": () => import("./../../../src/pages/home/products/Outsourcing/events/index.jsx" /* webpackChunkName: "component---src-pages-home-products-outsourcing-events-index-jsx" */),
  "component---src-pages-home-products-outsourcing-segments-index-jsx": () => import("./../../../src/pages/home/products/Outsourcing/segments/index.jsx" /* webpackChunkName: "component---src-pages-home-products-outsourcing-segments-index-jsx" */),
  "component---src-pages-home-products-outsourcing-why-index-jsx": () => import("./../../../src/pages/home/products/Outsourcing/why/index.jsx" /* webpackChunkName: "component---src-pages-home-products-outsourcing-why-index-jsx" */),
  "component---src-pages-home-products-qualy-frame-segments-index-jsx": () => import("./../../../src/pages/home/products/QualyFrame/segments/index.jsx" /* webpackChunkName: "component---src-pages-home-products-qualy-frame-segments-index-jsx" */),
  "component---src-pages-home-products-uniprocess-events-index-jsx": () => import("./../../../src/pages/home/products/Uniprocess/events/index.jsx" /* webpackChunkName: "component---src-pages-home-products-uniprocess-events-index-jsx" */),
  "component---src-pages-home-products-uniprocess-segments-index-jsx": () => import("./../../../src/pages/home/products/Uniprocess/segments/index.jsx" /* webpackChunkName: "component---src-pages-home-products-uniprocess-segments-index-jsx" */),
  "component---src-pages-home-products-uniprocess-why-index-jsx": () => import("./../../../src/pages/home/products/Uniprocess/why/index.jsx" /* webpackChunkName: "component---src-pages-home-products-uniprocess-why-index-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-page-2-js": () => import("./../../../src/pages/page-2.js" /* webpackChunkName: "component---src-pages-page-2-js" */),
  "component---src-pages-using-ssr-js": () => import("./../../../src/pages/using-ssr.js" /* webpackChunkName: "component---src-pages-using-ssr-js" */),
  "component---src-pages-using-typescript-tsx": () => import("./../../../src/pages/using-typescript.tsx" /* webpackChunkName: "component---src-pages-using-typescript-tsx" */),
  "component---src-templates-using-dsg-js": () => import("./../../../src/templates/using-dsg.js" /* webpackChunkName: "component---src-templates-using-dsg-js" */)
}

